body, button, input, select, optgroup, textarea{
    font-size: 12px;
    font-family: Roboto-regular;
    color: #333;
  }

  
.widget-color-1  .widget-header{
    background-color:#FF9933;
}
.widget-color-2  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-3  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-4  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-5  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-6  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-7  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-8  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-9  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-10  .widget-header{
    /* background-color:#cfb495; */
    background-color:#FF9933;
    background-color: #FF9933;

}
.widget-color-11  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}
.widget-color-12  .widget-header{
    background-color:#FF9933;
    background-color: #FF9933;
}

.widget .widget-header{
    /* box-shadow: 0 0 5px rgb(0,0,0,.4) !important; */
}
.usefullink-list-item-link{
    padding:5px;
}
.jh-links-block-main-header{
    padding: 5px;
}
.jh-usefullinks-results-block{
width:100%;
float:left;
padding:5px;
}
.usefullink-header{
width: 100%;
float: left;
line-height: 34px;
text-indent: 10px;
color: white;
text-transform: capitalize;
white-space: nowrap;
overflow: hidden;
text-overflow: ellipsis;
font-size: 14px;
background-color:#b19cd9;
}
.jh-usefullinks-block{
    
    border-radius: 2px;
	font-family: 'Roboto-Regular';
	font-size: 14px;
	margin-bottom: 20px;
	height: 192px;
	
}
.jh-usefullinks-block-title{
    font-family: 'Roboto-Bold';
	line-height: 36px;
	color: #82338d;
	text-transform: uppercase;
	padding-left: 10px;
	border: 1px solid #ddd;
	background-color: #f9ecfb;
}
.jh-usefullinks-block-list{
    border-left:1px solid #ddd !important;
    border-right:1px solid #ddd !important;
}
.sklmorelinks{
    padding:5px !important;
    float:right !important
}
.jh-employment-card{
    cursor: pointer;
}
  /*////// End widget colors //////*/




  .CreateTopic:hover{
	  background-color:none !important;
  }
.header .header-widget {
            float: none !important;
            padding: 25px 0 !important;
        }

        .header .nav li a {
            color: #252525;
            font-size: 12px;
            /* text-transform: uppercase; */
            padding: 5px 13px;
            display: block;
            position: relative;
            text-shadow: none;
        }

        .container {
            max-width: 100% !important;
        }

        .widget {
            padding: 0;
            box-shadow: 0 0 2px rgba(0,0,0,.4);
            margin: 0 0 10px 0;
            transition: all .4s;
            
        }
        .widget:hover{
            box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
        }
        .widget-header {
            position: relative;
            min-height: 35px;
            background: #fff;
             color: #555;
        }
        .widget-caption{
            width: calc(100% - 40px);
            float: left;
            line-height: 34px;
            text-indent: 10px;
            color: #333;
            text-transform: capitalize;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-size: 14px;
            font-weight: 700;
        }
        .widget-caption-icon{
            width: 28px;
            height: 28px;
            float: left;
            padding: 0  ;
            border-radius: 50%;
            margin-top: 4px;
            text-align: center;
            background-color: rgba(0,0,0,.15);
        }
        .widget-icon{
            font-size: 14px;
            line-height: 28px;
            color: #fff;
        }
        .bg-palegreen {
            background-color: #a0d468 !important;
        }

        .widget-buttons {
            display: inline-block;
            padding: 0 5px;
            line-height: 34px;
            position: relative;
            text-align: left;
            height: 36px;
        }

        .widget-body {
            width: 100%;
            float: left;
            background-color: #fbfbfb;
            
            padding: 0;	
            height:265px;
            position: relative;	
        }
        .widget-body *{
            font-size: 12px;
            color: #333;
        }
.widget-list{width: 100%;float: left;}
.widget-list-item{
    padding: 0 10px;
    line-height: 14px;
    float: left;
    width: 100%;
    border-bottom: 1px solid #eceaea;
  transition: 0.15s all ease;
}
.widget-list-item:hover{
    padding: 0 5px 0 15px;
}
.widget-list-item-link{
    width: 100%;
    float: left;
    padding: 0.29rem 0;
}
.widget-list-item-link .fa{
    margin-right: 3px;
    font-size: 10px;
    float: left;
    line-height: 13px;
}
.widget-list-text{
    width: calc(100% - 10px);
    float: left;
	text-transform: capitalize;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
}

.widget-list-text-link{
    font-size: 10px;
    color:#bb0606;
}
.widget-list-text-link:hover{text-decoration: underline;}

.widget-color-1 .widget-list-item:hover{background-color: #fdf0f9;}
.widget-color-2 .widget-list-item:hover{background-color: #fdf1ea;}
.widget-color-3 .widget-list-item:hover{background-color: #fdedeb;}
.widget-color-4 .widget-list-item:hover{background-color: #ffedf7;}
.widget-color-5 .widget-list-item:hover{background-color: #f8fbec;}
.widget-color-6 .widget-list-item:hover{background-color: #fffaf2;}
.widget-color-7 .widget-list-item:hover{background-color: #ebf0fb;}
.widget-color-8 .widget-list-item:hover{background-color: #fdf3ff;} 
.widget-color-9 .widget-list-item:hover{background-color: #f4f7ff;}
.widget-color-10 .widget-list-item:hover{background-color: #e7ebfd;}
.widget-color-11 .widget-list-item:hover{background-color: #e9f9fb;}

.widget-color-1 .widget-list-item:hover *{color: #ad1283;}
.widget-color-2 .widget-list-item:hover *{color: #FF5E0E;}
.widget-color-3 .widget-list-item:hover *{color: #F02F17;}
.widget-color-4 .widget-list-item:hover *{color: #ef017c;}
.widget-color-5 .widget-list-item:hover *{color: #73880a;}
.widget-color-6 .widget-list-item:hover *{color: #b38945;}
.widget-color-7 .widget-list-item:hover *{color: #2C539E;}
.widget-color-8 .widget-list-item:hover *{color: #ad1283;}
.widget-color-9 .widget-list-item:hover *{color: #3f4c6b;}
.widget-color-10 .widget-list-item:hover *{color: #08208a;}
.widget-color-11 .widget-list-item:hover *{color: #048290;}



.jh-nodata-banner-text{
	height:25px;
	}
.widget-view-all{
	float:right;
	padding-right:15px;
	color:#2C539E;
    text-decoration:none;
    font-size: 11px;
}
.jh-addyourbusiness-link{
	padding-left:15px;
	color:#2C539E;
    text-decoration:none;
    font-size: 11px;
}
.jh-addyourbusiness-link:hover,
.widget-view-all:hover{
    text-decoration: underline;
}
/* Style the tab */
.tab {
    overflow: hidden;
    border: 1px solid #ccc;
    background-color: orange;
}

/* Style the buttons inside the tab */
.tab button {
background-color: inherit;
float: left;
border: none;
outline: none;
cursor: pointer;
padding: 14px 16px;
transition: 0.3s;
font-size: 17px;
color:white;
}

/* Change background color of buttons on hover */
    .tab button:hover {
        background-color: #FF9933 ;
    }

/* Create an active/current tablink class */
    .tab button.active {
        background-color: #FF9933 ;
    }

/* Style the tab content */
.tabcontent {
display: none;
padding: 6px 12px;
border: 1px solid #ccc;
border-top: none;
}
/*Added by Jayanth*/

.dropdown-item{
    color:black !important;
}

.dropdown-item:hover{
    background-color:purple !important;
    color:white !important;
}
.footer{
    padding-top: 0 !important;
    padding-bottom: 0 !important;
    line-height: 16px;
    background-image: url('../img/footer_bg4.jpg');
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: center -200px;
}
.qrcodes{
	padding-right:50px !important;
	padding-left:10px !important;
}
.qrcode{
	border-radius:0 !important;
}
.qrtext{
	padding-top:10px;
	padding-bottom:5px;
	font-size:16px !important;
	text-align:center;
	
}
.progress-bar{
	background-color:blue !important;
}
.bg-warning{
	background-color:darkorange !important;
}
.bg-success{
	background-color:darkgreen !important;
}
.yellow-nav-block{
    /* min-width: 210px; */
    
    background-color: #fbfb47;
    
    line-height: 40px;
}
.yellow-nav-link{
     width: calc(100% - 20px); 
    float: left;
    font-size: 16px;
    font-family: 'Roboto-Medium';
    color: #333;
    text-decoration: none;
    text-align: center;
    transition: all .2s;
}
.yellow-nav-icon{width: 20px;float: left;font-size: 24px;color:rgba(0,0,0, .7);cursor: pointer;}
.yellow-nav-block-inner{width: 100%;float: left; cursor: pointer;}
.yellow-nav-block-inner:hover .yellow-nav-icon,
.yellow-nav-icon:hover,
.yellow-nav-link:hover{
    color: #f77f07;
}
.left-nav-sidebar{border-top: 1px solid #fff;}
.leftNav{
    background-color: #fbfb47; 
   
    padding-left:0px !important;
    padding-right: 0px !important;
    /* min-width: 210px;
	max-width:210px; */
}
.leftNavItem{    
    border-bottom:1px solid #fff;
    transition: all .5s;    
}
.leftNavLink{
    padding-left:15px !important;
   
    height: 32px;
    line-height: 16px;
    color: #333 !important;
    transition: all .5s;
}
.leftNavItem .nav-link:hover,
.left-nav-sidebar > .nav > .nav-item > .nav-link[aria-expanded="true"]{
    color: #333 !important;
    background: rgba(230,215,5,1);
background: -moz-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(230,215,5,1)), color-stop(100%, rgba(241,232,103,1)));
background: -webkit-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -o-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -ms-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: linear-gradient(to right, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6d705', endColorstr='#f1e867', GradientType=1 );
}
.newsIcon{
    width:137px !important;
    height: 82px !important;
}
.newslogo{
	max-height:24px !important;
	max-width:unset !important;
	margin-top:6px;
	
}
.BreakingNewsClass{
	max-height:24px !important;
	/* margin-top:6px !important; */
	
}
.newlogowrapper{
	padding-left:5px !important;
	
	
}
.jh-news-thumbnail-block{
	    
    border: none;
    border: 1px solid #82338d;
    background-color: #fff;
    border-radius: 1px;
    transition: background-color 350ms;
    box-shadow: none;
	background-color:#f8f1f9;
}
.newslogoinner{
	width: 40px;
    height: 40px;
    background-color: rgba(229, 229, 229, 0.6);
    margin-top: 4px;
    text-align: center;
}
.brknewslogoinner{
	width: 7
	0px;
    height: 40px;
    background-color: rgba(229, 229, 229, 0.6);
    margin-top: 4px;
	padding-left:8px;
	padding-right:8px;
	margin-bottom: 4px;
    text-align: center;
}

/* /////////// yellow pages lists ///////////*/
.jh-categories-list-block{}
.jh-categories-list-inner{
    background-color: #dec9e9;
    height: 30px;
    margin-bottom: 10px;
    line-height: 30px;
    padding: 0 10px;
    box-shadow: 2px 2px 3px rgba(0,0,0,.5);
    border-left: 10px solid #f5a38b;
    transition: all .2s;
}
.jh-categories-list-inner:hover{
    box-shadow: 1px 1px 2px rgba(0,0,0,.5);
}
.jh-categories-title{}
.jh-categories-title a{ color: #333; font-size: 16px;display: block}
/* /////////// End yellow pages lists ///////////*/
.yellow-card{
    height:180px !important;
}
.jh-card{
    margin-bottom: 20px;
    position: relative;
    
}
.jh-card-inner{
    height: 180px;
    
    border-radius: 2px;
    padding: 10px;
    position: relative;
    box-shadow: 0 0 5px rgba(0,0,0,.5);
    
}
.jh-card-bg-1{
    background: -moz-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #fbc4b4), color-stop(49%, #fbc4b4), color-stop(50%, #dec9e9), color-stop(100%, #dec9e9)); /* safari4+,chrome */
background: -webkit-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* opera 11.10+ */
background: -ms-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* ie10+ */
background: linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dec9e9', endColorstr='#fbc4b4',GradientType=1 ); /* ie6-9 */
}
.jh-card-bg-2{
    background: -moz-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #fbc4b4), color-stop(49%, #fbc4b4), color-stop(50%, #dec9e9), color-stop(100%, #dec9e9)); /* safari4+,chrome */
background: -webkit-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* opera 11.10+ */
background: -ms-linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* ie10+ */
background: linear-gradient(45deg, #fbc4b4 0%, #fbc4b4 49%, #dec9e9 50%, #dec9e9 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dec9e9', endColorstr='#fbc4b4',GradientType=1 ); /* ie6-9 */
}
.jh-card-bg-3{
    background: -moz-linear-gradient(45deg, #ffdc69 0%, #ffdc69 49%, #ffffff 50%, #ffffff 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, #ffdc69), color-stop(49%, #ffdc69), color-stop(50%, #ffffff), color-stop(100%, #ffffff)); /* safari4+,chrome */
background: -webkit-linear-gradient(45deg, #ffdc69 0%, #ffdc69 49%, #ffffff 50%, #ffffff 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(45deg, #ffdc69 0%, #ffdc69 49%, #ffffff 50%, #ffffff 100%); /* opera 11.10+ */
background: -ms-linear-gradient(45deg, #ffdc69 0%, #ffdc69 49%, #ffffff 50%, #ffffff 100%); /* ie10+ */
background: linear-gradient(45deg, #ffdc69 0%, #ffdc69 49%, #ffffff 50%, #ffffff 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffdc69',GradientType=1 ); /* ie6-9 */
}

.jh-card-bg-4{
    background: -moz-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(222,201,233,1) 50%, rgba(222,201,233,1) 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(222,201,233,1)), color-stop(50%, rgba(222,201,233,1)), color-stop(51%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1))); /* safari4+,chrome */
background: -webkit-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(222,201,233,1) 50%, rgba(222,201,233,1) 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(222,201,233,1) 50%, rgba(222,201,233,1) 100%); /* opera 11.10+ */
background: -ms-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(222,201,233,1) 50%, rgba(222,201,233,1) 100%); /* ie10+ */
background: linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(222,201,233,1) 50%, rgba(222,201,233,1) 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#dec9e9',GradientType=1 ); /* ie6-9 */
}
.jh-card-bg-5{
    background: -moz-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(251,196,180,1) 50%, rgba(251,196,180,1) 100%); /* ff3.6+ */
background: -webkit-gradient(linear, left bottom, right top, color-stop(0%, rgba(251,196,180,1)), color-stop(50%, rgba(251,196,180,1)), color-stop(51%, rgba(255,255,255,1)), color-stop(100%, rgba(255,255,255,1))); /* safari4+,chrome */
background: -webkit-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(251,196,180,1) 50%, rgba(251,196,180,1) 100%); /* safari5.1+,chrome10+ */
background: -o-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(251,196,180,1) 50%, rgba(251,196,180,1) 100%); /* opera 11.10+ */
background: -ms-linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(251,196,180,1) 50%, rgba(251,196,180,1) 100%); /* ie10+ */
background: linear-gradient(225deg, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 49%, rgba(251,196,180,1) 50%, rgba(251,196,180,1) 100%); /* w3c */
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#FFFFFF', endColorstr='#fbc4b4',GradientType=1 ); /* ie6-9 */
}
.jh-card-header{
    height: 50px;
    text-align: center;
}
.jh-card-title a{
    font-family: 'Roboto-Bold';
}
.jh-card-body{
}
.jh-card-separator{
    margin: 10px 0;
    background-color: #f9a086;
}
.jh-discussion-cardheader{
	padding-bottom:5px !important;
}
.jh-discussion-userip{
	padding-left:22px !important;
}
.tiles-address-text{
    width: calc(100% - 20px);
    float: left;
    color: #333;
}
.tiles-phone-icon,
.tiles-address-icon{
    padding-right:10px !important;
    float: left;
}
.jh-employment-filter{
    padding-bottom: 15px !important;
}
.jh-employment-card-1{
  
}
.jh-adv4-wrapper{
    padding:0 10px 5px 15px !important;
}
.jh-adv4-img-wrapper{
    padding: 5px !important;
}
.left-nav-sidebar .nav-item{
    border-bottom: 1px solid #fff;
    transition: all .5s;
}
.left-nav-sidebar > .nav > .nav-item > .nav-link{
    padding-left: 15px!important;
    height: 32px;
    line-height: 16px;
    color: #333!important;
    transition: all .5s;
    position: relative;
    }

.left-nav-sidebar div .nav-item{
    border-bottom: 1px solid #fff;
    background-color: #f7f773;
    transition: all .5s;
    word-break: break-word;
    white-space: normal;
}
.left-nav-sidebar div .nav-item .nav-link{
    font-size: 12px;
    color: #333;
    line-height: 14px;
    padding: 5px 0 5px 25px;
    word-break: break-word;
    white-space: normal;
}
.left-nav-sidebar .nav-item:hover{
    color: #333 !important;
    background: rgba(230,215,5,1);
background: -moz-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -webkit-gradient(left top, right top, color-stop(0%, rgba(230,215,5,1)), color-stop(100%, rgba(241,232,103,1)));
background: -webkit-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -o-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: -ms-linear-gradient(left, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
background: linear-gradient(to right, rgba(230,215,5,1) 0%, rgba(241,232,103,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#e6d705', endColorstr='#f1e867', GradientType=1 );

}

.left-nav-sidebar > .nav > .nav-item > .nav-link:after{
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 8px;
    border: none!important;
    font-size: 16px;
    
}


/*/////////// polls page ////////////*/
.jh-polls-results{padding-left: 5px;padding-right: 5px;}
.jh-polls-results .MuiDialogContent-root{
    border-radius: 2px;
    padding-left: 0;
    padding-right: 0;
}
.jh-results-block-main,
.jh-results-block-main-header,
.jh-results-block-main-body{
    float: left;
    width: calc(100% - 10px);
}
.jh-results-block-main-header,
.jh-results-block-main-body{
    background-color: #fff;
    margin: 0 5px;
}

.jh-results-block-main{
    width: calc(100% - 20px);
    padding: 0;
    margin: 0 10px;
    box-shadow: 0 1px 3px 1px rgba(0,0,0,.20);
}
.jh-usefulinks-block-main-header{
    background-color: lightslategray;
}
.jh-results-block-main-body{
    
}


.jh-polls-results-block{
    width: 100%;
    float: left;
    padding:5px 20px 15px;
}
.jh-polls-results-block:nth-child(2){
    background-color: #fff;
}
.jh-polls-results-block-warning{}
.jh-polls-results-block-success{}
.jh-polls-results-block-title{width:100%; float: left;}

.jh-polls-results-block-main-title{
    width: 100%; 
    float: left;
    height: 40px;
}
.jh-polls-results-block-main-title h6{
    font-size: 12px;
    font-family: 'Roboto-Bold';
    text-align: center;
    font-weight: normal;
    line-height: 40px;
    color: #54035f;
}
.jh-polls-results-block-title{width: 100%;float: left;}
.jh-polls-results-block-title .MuiTypography-body1{
    font-family: 'Roboto-Regular';
    font-size: 12px;
    margin-bottom: 0;
    color: #54035f;
}
.jh-polls-results-block-progress{width:calc(100% - 40px);float: left;}
.jh-polls-results-block-progress .progress{
  
    height: 9px;
    position: relative;
    border-radius: 0;
    background-color: #fff;
}
.jh-polls-results-block-progress .progress .progress-bar{
    z-index: 1;
    text-indent: 5px;
    background: rgb(0,185,191);
    background: linear-gradient(90deg, rgba(0,185,191,1) 0%, rgba(0,199,217,1) 100%);
}
.progress-bar-votes{
    font-size: 10px;
    color: #54035f;
}
.jh-polls-results-block-progress .progress .progress-bar.bg-warning{
    background: rgb(44,110,242);
background: linear-gradient(90deg, rgba(44,110,242,1) 0%, rgba(110,154,244,1) 100%);
}

.jh-polls-results-block-progress .progress .progress-bar.bg-success{
    background: rgb(213,48,122);
    background: linear-gradient(90deg, rgba(213,48,122,1) 0%, rgba(247,65,154,1) 100%);
}
.jh-progress-bar-shadow{
    line-height: 18px;
    position: absolute;
    height: 16px;
    z-index: 0;
    color: #007bff;
    background-color: transparent;
    text-indent: 5px;
}
.jh-polls-results-block-percent{width:40px;float: left;margin-top: -1px;}

.jh-polls-results-block-percent .MuiTypography-body1{
    font-family: 'Roboto-Bold';
    font-size: 11px;
    text-align: right;
    color: #54035f;
    line-height: 10px;
}
.jh-polls-results-block-warning .progress{
    
}
.jh-polls-results-block-success .progress{
    
}
.jh-polls-results-block-warning .jh-progress-bar-shadow,
.jh-polls-results-block-warning .jh-polls-results-block-percent .MuiTypography-body1{}
.jh-polls-results-block-success .jh-progress-bar-shadow,
.jh-polls-results-block-success .jh-polls-results-block-percent .MuiTypography-body1{}










.leftnavwrapper1{
    padding:0 !important;
    width:100% !important;
    background-color: #fbfb47 !important;
}
.leftnavwrapper2{    
    width:100% !important;
    background-color: #fbfb47 !important;
}
.submenuwrapper{
    background-color: #f3e260 !important;
    border-radius: 0;
    padding:0;
}
.submenuwrapper .navbar-nav .nav-item{
    border-bottom: 1px solid #fff;
    background-color: #f7f773;
    transition: all .5s;
	word-break: break-word;
    white-space: normal;

}
.submenuwrapper .navbar-nav .nav-item:last-child{
    border-bottom:none;
}
.submenuwrapper .navbar-nav .nav-item .nav-link{padding-left: 25px;
word-break: break-word;
    white-space: normal;
}
.leftNavSubLink{
    font-size:12px !important;
    color:#333;
    line-height: 14px;
    padding:5px;
}
.submenuwrapper .navbar-nav .nav-item:hover{
    background: rgba(230,215,5,1);
}
.submenuwrapper .navbar-nav .nav-item .nav-link:hover{
    color:#333;
}
.leftnavwrapper2 .dropdown-toggle:after {
    font-family: "FontAwesome";
    font-weight: 900;
    content: "\f105";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 8px;
    
}
#jh-sidebar-wrapper .dropdown-toggle::after{
    border: none !important;
    font-size: 16px;
}
#jh-sidebar-wrapper a[aria-expanded="true"]:after{
   
	font-family: "FontAwesome";
    font-weight: 900;
    content: "\f107";
    font-style: normal;
    display: inline-block;
    font-style: normal;
    font-variant: normal;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    background: 0 0;
    position: absolute;
    right: 15px;
    top: 8px;
}
#jh-sidebar-wrapper a[aria-expanded="true"]:after{
    margin-right: 0 !important;
}
.bg-dark-purple a[aria-expanded="true"]:after{
    float: none !important;
    margin-right: 0 !important;
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
    content: "";
    border-top: .3em solid !important;
    border-right: .3em solid transparent !important;
    border-bottom: 0 !important;
    border-left: .3em solid transparent !important;
}
.header .top-header-widget {
    
    width: 100%;
    float: left;   
}
.top-header-widget-banner{}
@keyframes banner-text-animation {
    0%{background-position:0% 50%}
    100%{background-position:100% 50%}
  }
.jh-banner-text{
    width: 100%;
    float: left;
    color: #82338d;
    font-size: 14px;
    font-family: 'Roboto-BoldItalic';
    margin-bottom: 1px;
    line-height: 24px;
    text-transform: capitalize;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
	text-align:center;
    animation: banner-text-animation 5s linear infinite;
  
  background: linear-gradient(90deg, rgba(150,48,30,1) 0%,rgba(199,201,88,1) 10%,rgba(28,147,46,1) 20%,rgba(74,165,168,1) 30%,rgba(89,90,165,1) 40%,rgba(84,16,67,1) 50%,rgba(28,58,63,1) 60%,rgba(82,175,183,1) 70%,rgba(78,170,76,1) 80%,rgba(150,48,30,1) 90%,rgba(199,201,88,1) 100%);
  background-size: 1000% 100%;
  
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.jh-banner-image{
    width: 100%;
    min-height: 84px;
    float: left;
    background: radial-gradient(circle, rgba(63,94,251,1) 0%, rgba(252,70,107,1) 100%);
	/* padding-top:25px; */
}
.jh-banner-image img{
    min-width: 100%;
    min-height: 84px;
    max-height: 84px;
    /* height: 50px;  */
	/* border:1px solid purple !important; */
   }
.MuiDialog-paperWidthSm{
}
.MuiDialog-paperWidthLg{
    max-width: 978px !important;
}
.MuiDialog-scrollPaper{
    align-items:center;
}
.MuiDialog-container{
     height:100%; 
}
.cityWrapper{   
    float: right;    
    width: 100%;
    height: 50px;
    margin-top: 25px;
    border-left: 1px solid purple;
    border-top: 1px solid purple;
    border-bottom: 1px solid purple;

}
.fadown{
    cursor: pointer !important;
}

.search-txt{
    border-radius:0px !important;
}
.search-btn{
    border-radius:0px !important;
}
.search-btn-disable{
	pointer-events: none;
}
.jh-location-dialog{}
.jh-location-city-thumnails{
    width: 100%;
    margin: 20px auto 0;
}
.MuiDialogContent-root.jh-location-dialog-dialog-content{
    padding-left: 15px;
    padding-right: 15px;
}
.city-logo{
    cursor: pointer;
    width: 50px;
}
.MuiAutocomplete-paper{
    margin-top:50px !important;
}
.city-dailog-wrapper{
}
.jh-search-item-direction{
	text-decoration:underline !important;
}
.city-search{
    margin-top:25px !important;
   
}
.city-txt{
    height: 50px !important;
    border-color:#82338d;
    border-right-color: white !important;
}
.city-search-clear-fa{
    color:#666 !important;
}
.city-search-clear{
    border-radius: 0 !important;
    background-color: #fff !important;
    border:1px solid #82338d;
    border-left-color: white !important;
}
.citysearchResultsWrapperUL{
    border-radius:0 !important;
}
.citiesWrapperUL{
    border-radius:0 !important;
}
.citiesWrapper{
    border-left:1px solid #82338d;
    border-right:1px solid #82338d;
    border-bottom:1px solid #82338d;
}
.citysearchResultsWrapper{   
   width: 98%;
    position: absolute;
    z-index: 1000;
    border-left:1px solid #82338d;
    border-right:1px solid #82338d;
    border-bottom:1px solid #82338d;
}
.searchResultsWrapper{
    width:95.5%;
   
    position: absolute;
    z-index: 1000;
    
}
.searchResultsWrapperUL{
   
    border-radius:0 !important;
}
.jh-ta-search-item{
    padding:.5rem !important;
}

.jh-ta-city-search-item{
    padding:.5rem !important;
    border: 0 !important;
    cursor: pointer !important;
}
.jh-ta-city-search-item:hover{
    background-color:whitesmoke !important;
  color:black !important;
}
.jh-ta-city-search-item a{
    color:black !important;
}

.jh-ta-search-item:hover{
    background-color:whitesmoke !important;
  color:white !important;
}
.jh-ta-search-item a{
    color:black !important;
}

.search-item{
    padding:0.2rem 0.1rem !important; 
}
.searchWrapDIV{
    padding-left:30px !important;
    padding-top:5px !important;
}
.jh-franchise-dailog-title{
    background-color: purple;
    padding:10px !important;	
    color:white !important;
    font-weight: bold;
}
.breadcrumb{
    background-color: white;
    margin-bottom: 0 !important;
    margin-left: 15px;
}
.breadcrumb-item a{
    color: #ff9933;
}
.searchresults-item{
padding-left:25px !important;
padding-top:10px !important;
} 
.searchresults-tem-url{
    padding-left:25px !important;
    padding-top:5px !important;
    color:green !important;
}
.weather-icon-wrap {
    margin-top: 25px!important;
    height: 50px;
    border-top: 1px solid purple;
    border-right: 1px solid purple;
    border-bottom: 1px solid purple;
}
.citywrap, .weather-icon-wrap {
    padding: 0!important;
}
.city-weather-wrap {
    margin: 0!important;
}
.pagingWrapper{
    padding:10px !important;
}
/*////////// main nav ////////////////*/
.mainNav{
    background-color: purple;
    width: calc(100% - 210px);
}

/*////// polling block //////*/
.jh-block-polling .widget-body{
    padding:10px
  }
  .widget-body-polling-inner{}
  .jh-block-polling .MuiTypography-body1{
      font-size: 12px;
  }
  .jh-block-polling .progress{
      margin-bottom: 5px;
  }
  .jh-polling-qn{
    width: 100%;
    float: left;
    margin-bottom: 10px;
  }
  .jh-polling-options{
    width: 100%;
    float: left;
    margin: 0;
    padding:0;
  }
  .jh-polling-options-list{
    width: 100%;
    float: left;
  }
  .jh-polling-label{
    width: 100%;
    float: left;
    text-align: left;
  }
  .jh-polling-radio{
    float: left;
    margin-right: 10px;
    margin-top: 2px;
  }
  .jh-polling-btn-blk{
    width: 100%;
    float: left;
    text-align: center;
    margin-bottom: 10px;
  }
  
.jh-polling-btn{
    width: 100%;
    border-radius: 2px;
    background-color: #FF9933 ;
    color: #fff
  }
  .jh-polling-btn:hover{
      color: #fff;
  }
/*////// end polling block //////*/

/*///// card //////*/

.card-section{
    width: 100%;
}
.card{padding: 20px 0;}
.jh-card-page-title-block{
    background-color: #fff;
}
.jh-card-page-title{
    font-size: 20px;
    padding: 15px;
    font-family: 'Roboto-Bold';
}
.card-section .card-body{
    padding: 0 15px 15px;
}
.jh-card-address-blocks{
    width: 100%;
}
.jh-card-address-block{
    float: left;
    width: 100%;
}
.jh-card-address-block-left{width: 100px; float: left;}
.jh-card-address-block-right{width:calc(100% - 100px);float:left;}
.jh-card-address-block-inner{width:100%; float: left;margin-bottom: 15px;}

.jh-card-address-blocks  p,
.jh-card-address-blocks  a{color: #999;}
.jh-card-address-blocks  a:hover{
    color: #FF9933 ;
}
.jh-card-address-text > p{margin-bottom: 10px;}
.jh-card-address-block i{
    color: #999;
    font-size: 16px;
}
.jh-card-address-blocks hr{
    width: 100%;
    float: left;
    border-bottom: 1px solid #ccc;
    margin: 10px 0 20px 0;
    height: 1px;
    background-color: #fff;
}
.jh-card-address-block-title{
    font-family: 'Roboto-Bold';
    color: #333 !important;
    margin-bottom: 10px;
}

.jh-card-address-block-icons{width: 20px;float: left;text-align: center;}
.jh-card-address-block-details{width: calc(100% - 25px);float: left;margin-left: 5px;}


.jh-card-columns .yellow-card:hover{
}
.jh-mobile-icon{
	color:green !important;
}

/*//////////////// common styles /////////////*/
.jh-title-1,
.jh-title-8{
    position: relative;
    font-family: 'Roboto-Bold';
}
.jh-title-1{
    font-size: 30px;
    margin-bottom: 20px;
    padding-bottom: 20px;
}
.jh-title-8{
    font-size: 16px;
    margin-bottom: 20px;
}
.jh-title-1::before,
.jh-title-6::before{
    position: absolute;
    left: 0;
    bottom: -1px;
    width: 60px;
    height: 2px;
    content: "";
    background: #FF9933 ;
}

.form-control{
    border-radius: 0;
}
.form-control:focus{
    box-shadow: none;
    border-color: #82338d;
}
.btn{
    border-radius: 2px;
}
.btn-primary{
    color: #fff;
    background-color: #82338d;
    border-color: #82338d;
}
.btn-secondary{
    color: #82338d;
    background-color: #fff;
    border-color: #82338d; 
}
.btn-primary:hover,
.btn-secondary:hover{
    color: #fff;
    background-color: #7d2e88;
    border-color: #7d2e88;
}
.listeningicon{
    color: purple;
    font-size: 32px;
}
.txtlisten{
    font-size: 18px;
}
/*//////////////// end common styles /////////////*/


#jh-wrapper {
    overflow-x: hidden;
  }
  
  #jh-sidebar-wrapper {
    /* background-color: #fbfb47; */
    background-color: #fffaa2;
  min-height: 100vh;
  margin-left: -211px;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  }
  
  #jh-sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
  }
  
  #jh-sidebar-wrapper .list-group {
  width: 211px;
  }
  
  #page-content-wrapper {
  min-width: 100vw;
  }
  
  #jh-wrapper.toggled #jh-sidebar-wrapper {
  margin-left: 0;
  }
  .jh-page-content-wrapper{width: 100%;
    /* padding-left: 5px;padding-right: 5px; */
}
  @media (min-width: 768px) {
  #jh-sidebar-wrapper {
    margin-left: 0;
  }
  
  #page-content-wrapper {
    min-width: 0;
    width: 100%;
  }
  
  #jh-wrapper.toggled #jh-sidebar-wrapper {
    /* margin-left: -211px; */
    transition-property: width;
    transition-duration: 5s;
    max-width: 0;
  }
  }
  .contact{
      padding:0px !important;
      margin-top: 30px;
  }
  .jh-contact-description{
      margin-bottom: 50px;
  }
  .jh-contact-description .jh-paragraph p{
    text-align: justify;
  }
  .jh-contact-form{
      margin-bottom: 50px;
  }
  .jh-contact-details{}
/*///// city changes //////*/
.MuiGridListTile-root{
    height: 90px !important;
}
.MuiDialogTitle-root{
    padding: 5px 10px 0px 24px !important;
}
.city-dropdown{
    border-radius: 2px;
    border-color: #ddd;
}
.advReferenceImg{
	background-color:purple;padding:5px;color:white;
}
.jh-mdl-appdownload-close{
	font-size:24px !important;
	cursor:pointer !important;
}
.jh-eventswrapper{
	padding:25px;
}
@media (max-width: 1200px) {  
    
} /* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1050px) {  
    .jh-city-weather{width: 100%;}
    .jh-city-weather-icon{display: none}
} /* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 992px) {  
    .jh-header-app-block.JaiFranchiseTXT{
        margin-right: 0;
    }
    .JaiFranchiseTXT{max-width: calc(100% - 138px);}
    .footer{
        background-size: cover;
        background-position: unset;
    }
    .jh-contact-details{margin-bottom: 20px;}
    .bg-dark-purple .navbar-collapse{
        width: calc(100% + 210px);
        left: -210px;
        position: absolute;
        top: 40px;
    }
    .mn-hvr{display: block}
}/* // Large devices (desktops, 992px and up) */


@media (max-width: 768px) { 
    .jh-sticky-top{
        position: relative;
    }
    /* .header .top-header-widget{
      margin-top: 20px;
    } */
    .cityWrapper{margin-top: 0;}
    /* .jh-city-weather{max-width: calc(100% - 50px);width: auto;} */
    .jh-city-weather-icon{display: block}
    .jh-city-weather-block-inner{width: auto;float: right;padding: 0 10px;}
    /* .footer-top{
        width: 60%;
        margin:0 auto;
    } */
    
    .jh-banner-text{text-align: center !important;}
    .jh-banner-image{text-align: center;padding-top: 0;}
    /* .header .header-menu{margin-top: 15px;} */
    .cityWrapper{width: auto;border:none}
    /* .right-thumbnail-banner{width: 25%;} */

    /* .yellow-nav-block,
    .mainNav{width: 50%;} */
    .bg-dark-purple .navbar-collapse{
        width: 200%;
        left: -40%;
        position: absolute;
        top: 40px;
    }
    /* #jh-sidebar-wrapper{
        position: absolute;
        z-index: 9;
    } */
    
}/* // Medium devices (tablets, 768px and up) */

@media (max-width: 576px) {  
    .footer-top{
        width: 80%;
        margin:0 auto;
    }
    .right-thumbnail-banner{width: 33.333333333333336%;}
    .jh-contact-form .btn-primary{margin-bottom: 20px;}
}

.header-container{
    padding:0px; 
    background-color: #fff;  
}
.header-row{
    padding: 0px 0px 2px;   
}
.brand-logo-container,.search-container{
    display: flex;
    align-items: center;
}
#jh-wrapper.toggled .jh-inner-page-main-content {
    flex: 0 0 98.7%;
    max-width: 98.7%;
}
.jh-header-search-icon .input-group-text{
    min-height: 30px;
    max-height: 30px;
}
@media only screen and (max-width:1100px){
    .bg-dark-purple.navbar-dark .navbar-nav .nav-link{
        font-size: 12px;
    }
    .jh-header-app-text{
        max-width: 150px;
    }

}

@media only screen and (min-width:1024px) and (max-width:1024px){
    #jh-wrapper.toggled .jh-inner-page-main-content {
        flex: 0 0 98.6%;
        max-width: 98.6%;
    }
    .header-container .header-row{
        display: flex;
        align-items: center;
        padding: 10px 5px;
    }
    .brand-logo-container{
        flex: 0 0 8%;
    max-width: 8%;
    }
    .app-container{
        flex: 0 0 13% !important;
        max-width: 13% !important;
    }
    .search-container{
        flex: 0 0 25% !important;
        max-width: 25% !important;
    }
    .banner-add-container{
        flex: 0 0 54%;
    max-width: 54%;
   
    }
    .jh-header-search{
        margin-top:-25px;
    }
    #root{
        min-width: 1200px;
    }
  .jh-carousel-notification-list{
padding:2px;
  }
  .JaiAppDownloadTXT{
    max-width: 143px;
    min-width: 143px;
  }
}
.search-mobile{display: none;}
@media only screen and (min-width:767px) and (max-width:1023px){
    .jh-header-app-block.JaiFranchiseTXT {
        display: flex;
        max-width: 143px;
        min-width: 143px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
 
}

@media only screen and  (min-width:426px) and (max-width:768px){
    .brand-logo-container{
        flex: 0 0 15%;
        max-width: 15%;
    }
    .app-container{
        flex: 0 0 22% !important;
        max-width: 22% !important;
    }
    .search-container{
        flex: 0 0 63% !important;
        max-width: 63% !important;
    }
    .banner-add-container{
        flex: 0 0 100% !important;
    max-width: 100% !important;
    margin-top:5px
    }
    .jh-city-weather-block{
        width: unset;
    }
    .jh-header-app-block.JaiFranchiseTXT{
        display: flex;
        max-width: 145px;
        min-width: 145px;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
    .JaiAppDownloadTXT{
        max-width: 145px;
        min-width: 145px;
    }
    .jh-header-search{
        margin-top: unset;
    }
  
    .carousal2{
        padding-left: 0px;
        margin-top:5px;
    }
    #jh-wrapper.toggled .jh-inner-page-main-content {
        flex: 0 0 97%;
        max-width: 97%;
    }
    .left-nav-sidebar .nav-item:hover .nav-link {
        font-size: 12px;
    }
}
@media only screen and (min-width:375px) and (max-width:375px){
    .JaiAppDownloadTXT{
min-width: 145px;
    }
}
@media only screen and (max-width:425px){
    .brand-logo-container{
        width:25%;
    }
    .app-container{
        flex: 0 0 75% !important;
        max-width: 75% !important;
    }
    .search-container{
        display: none;
    }
    .search-mobile{
        display: block;
    }
    .app-container .jh-header-app-download{margin:5px 0px!important;}
    .jh-header-app-block.JaiFranchiseTXT{
        display: flex;
        min-width: 145px;
        max-width: 145px;      
        margin-right: 10px;
    }
    .jh-header-search{
        margin: 10px 0px;
    }
    .yellow-nav-block{
        min-width: 40%;
        max-width: 40%;
    }
.mainNav{
    min-width: 60%;
    max-width: 60%;
}
#jh-sidebar-wrapper{
    margin-left:0;
    min-width: 40%;
    max-width: 40%;
}
.jh-main-content.jh-inner-page-main-content{
    min-width: 60%;
    max-width: 60%;
}
.carousal2{
    padding-left: 0px;
    margin-top:5px;
}
#jh-wrapper.toggled #jh-sidebar-wrapper {
    margin-left: -176px;
}
#jh-wrapper.toggled .jh-inner-page-main-content {
    flex: 0 0 100%;
    max-width:100%;
    padding:0px 0px 0px 15px !important;
}
.jh-widget-blocks.row > div{
    padding-right:0px;
}
.footer-inner .qrcodes{
    display: flex;
    justify-content: center;
    padding: 10px 0px 0px;

}
.footer-inner .qrcodes > div{
width: unset;
}
.footer-inner .jh-move-up-button{
bottom: 15px;
}
}
@media only screen and (max-width:424px){
    #jh-wrapper.toggled #jh-sidebar-wrapper {
        margin-left: -156px;
    }
}
@media only screen and (min-width:400px) and (max-width:423px){
#jh-wrapper.toggled .jh-inner-page-main-content{
    flex: 0 0 96%;
    max-width:96%;
    padding:0px !important;
}
}
@media only screen and (min-width:769px) and (max-width:1214px){
    #jh-wrapper.toggled .jh-inner-page-main-content{
        flex: 0 0 97.7%;
        max-width:97.7%;
    }
}
@media only screen and (max-width:767px) and (min-width:426px){
#jh-sidebar-wrapper,.yellow-nav-block{
    flex:0 0 30%;
    max-width: 30%;
}
.mainNav,#jh-wrapper.toggled .jh-inner-page-main-content {
    flex:0 0 70% !important;
    max-width: 70% !important;
}
.jh-inner-page-main-content{
    flex:0 0 98%;
    max-width: 98%;
}
.jh-main-content .cards-container{
    flex:0 0 80%;
    max-width: 80%;
}
.vadd-container{
    flex:0 0 20%;
    max-width: 20%;
}
.jh-adv4-img-wrapper{
    flex:0 0 50%;
    max-width: 50%;
}
#jh-sidebar-wrapper{
 margin-left: -219px;   
}
.search-container{
    flex:0 0 50%;
    max-width: 50%;
}

}
