/*!
Theme Name: LearnEdu
Theme URI: http://ithemer.com/
Author: Underscores.me
Author URI: http://underscores.me/
Description: Description
Version: 1.0.0
License: GNU General Public License v2 or later
License URI: LICENSE
Text Domain: l-LearnEdu
Tags: custom-background, custom-logo, custom-menu, featured-images, threaded-comments, translation-ready

This theme, like WordPress, is licensed under the GPL.
Use it to make something cool, have fun, and share what you've learned with others.

eEducation is based on Underscores https://underscores.me/, (C) 2012-2017 Automattic, Inc.
Underscores is distributed under the terms of the GNU GPL v2 or later.

Normalizing styles have been helped along thanks to the fine work of
Nicolas Gallagher and Jonathan Neal https://necolas.github.io/normalize.css/
*/
/*--------------------------------------------------------------
>>> TABLE OF CONTENTS:
--------------------------------------------------------------*/

/*--------------------------------------------------------------
# Normalize
--------------------------------------------------------------*/
@font-face {
    font-family: Roboto-regular;
    src: url(../asserts/fonts/Roboto/Roboto-Regular.ttf);
}
@font-face {
    font-family: Roboto-Black;
    src: url(../asserts/fonts/Roboto/Roboto-Black.ttf);
}
@font-face {
    font-family: Roboto-BlackItalic;
    src: url(../asserts/fonts/Roboto/Roboto-BlackItalic.ttf);
}
@font-face {
    font-family: Roboto-Bold;
    src: url(../asserts/fonts/Roboto/Roboto-Bold.ttf);
}
@font-face {
    font-family: Roboto-BoldItalic;
    src: url(../asserts/fonts/Roboto/Roboto-BoldItalic.ttf);
}
@font-face {
    font-family: Roboto-Italic;
    src: url(../asserts/fonts/Roboto/Roboto-Italic.ttf);
}
@font-face {
    font-family: Roboto-Light;
    src: url(../asserts/fonts/Roboto/Roboto-Light.ttf);
}
@font-face {
    font-family: Roboto-LightItalic;
    src: url(../asserts/fonts/Roboto/Roboto-LightItalic.ttf);
}
@font-face {
    font-family: Roboto-Medium;
    src: url(../asserts/fonts/Roboto/Roboto-Medium.ttf);
}
@font-face {
    font-family: Roboto-MediumItalic;
    src: url(../asserts/fonts/Roboto/Roboto-MediumItalic.ttf);
}
@font-face {
    font-family: Roboto-Thin;
    src: url(../asserts/fonts/Roboto/Roboto-Thin.ttf);
}
@font-face {
    font-family: Roboto-ThinItalic;
    src: url(../asserts/fonts/Roboto/Roboto-ThinItalic.ttf);
}

html {
	 font-family: 'Roboto-regular', sans-serif;
	-moz-text-size-adjust: 100%;
	-webkit-text-size-adjust: 100%;
	-ms-text-size-adjust: 100%;
	text-size-adjust: 100%;
}
html,body {
	height: 100%;
	margin: 0;
	position:relative;
}
*{
	padding:0;
	margin:0;
}
body {
	margin: 0;
}
img{
	max-width:100%;
}
article,
aside,
details,
figcaption,
figure,
footer,
header,
main,
menu,
nav,
section,
summary {
	display: block;
}
audio,
canvas,
progress,
video {
	display: inline-block;
	vertical-align: baseline;
}
audio:not([controls]) {
	display: none;
	height: 0;
}
[hidden],
template {
	display: none;
}
a, img,input,textarea{
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
a {
	text-decoration:none;
}
a:active,
a:hover {
	outline: 0;
	text-decoration:none;
}
.slicknav_menu{
	display:none;
}
img {
	border: 0;
}
svg:not(:root) {
	overflow: hidden;
}
figure {
	margin: 1em 40px;
}
hr {
	box-sizing: content-box;
	height: 0;
}
pre {
	overflow: auto;
}
button,
input,
optgroup,
select,
textarea {
	color: inherit;
	font: inherit;
	margin: 0;
}
button {
	overflow: visible;
}
button,
select {
	text-transform: none;
}
button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
	-webkit-appearance: button;
	cursor: pointer;
}
button[disabled],
html input[disabled] {
	cursor: default;
}
button::-moz-focus-inner,
input::-moz-focus-inner {
	border: 0;
	padding: 0;
}
input {
	line-height: normal;
}
input[type="checkbox"],
input[type="radio"] {
	box-sizing: border-box;
	padding: 0;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
	height: auto;
}
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
	-webkit-appearance: none;
}
legend {
	border: 0;
	padding: 0;
}
textarea {
	overflow: auto;
}
optgroup {
	font-weight: bold;
}
table {
	border-collapse: collapse;
	border-spacing: 0;
}
td,
th {
	padding: 0;
}
.jh-move-up-button{
	position: absolute;
	right:20px;
	bottom: 0;
	width: auto;
}
#scrollUp {
	/* right: 20px; */
	width: 45px;
    height: 45px;
    line-height: 45px;
    z-index: 33;
    font-size: 30px;
    bottom: 10px;
    text-align: center;
    border-radius: 50%;
    border: 2px solid rgba(231, 160, 7, 0.5);
    background-color: rgba(255,255,255, 0.1) !important;
    color: rgba(226, 157, 7, 0.5);
	/* -webkit-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
	-moz-box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
	box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16); */
	
}
#scrollUp .fa{
	float: left;
    margin: -5px 0 0 11px;
	/* bottom:15px; */
}
.table {
	display: table;
	width: 100%;
	height: 100%;
}
.table-cell {
	display: table-cell;
	vertical-align: middle;
}
/*--------------------------------------------------------------
# Typography
--------------------------------------------------------------*/
body,
button,
input,
select,
optgroup,
textarea {
	font-family: 'Roboto-regular', sans-serif;
	font-weight: 400;
	font-size:14px;
	color:#252525;
	line-height:16px;
	position:relative;
}
h1, h2, h3, h4, h5, h6 {
	font-weight: 700;
	margin: 0;
}
p {
	color:#666;
	margin: 0;
}
a,button{
	font-weight:500;
}
input{
	font-weight:400;
}
a{
	-webkit-transition:all 0.3s ease;
	-moz-transition:all 0.3s ease;
	transition:all 0.3s ease;
	outline:none;
	box-shadow:none;
	text-decoration:none;
	cursor:pointer;
}
a:hover{
	text-decoration:none;
}
img,a,input,h1, h2, h3, h4, h5, h6{
	-webkit-transition:all 0.4s ease;
	-moz-transition:all 0.4s ease;
	transition:all 0.4s ease;
}
dfn, cite, em, i {
	font-style: italic;
}
address {
	margin: 0 0 1.5em;
}
pre {
	background: #eee;
	font-family: 'Roboto-regular', sans-serif;
	font-size: 15px;
	font-size: 0.9375rem;
	line-height: 1.6;
	margin-bottom: 1.6em;
	max-width: 100%;
	overflow: auto;
	padding: 1.6em;
}
code, kbd, tt, var {
	font-family: 'Roboto-regular', sans-serif;
	font-size: 15px;
	font-size: 0.9375rem;
}

abbr, acronym {
	border-bottom: 1px dotted #666;
	cursor: help;
}

mark, ins {
	background: #fff9c0;
	text-decoration: none;
}

big {
	font-size: 125%;
}

.container{
	max-width:1170px;
}
.overlay{
	position:relative
}
.overlay::before {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0.5;
	background: #000;
	content: "";
	-webkit-transition: all 0.4s ease;
	-moz-transition: all 0.4s ease;
	transition: all 0.4s ease;
}
.button .btn {
	font-weight: 500;
	text-align: center;
	color: #252525;
	display: inline-block;
	padding: 13px 32px;
	border-radius: 0px;
	text-transform: uppercase;
	cursor: pointer;
	font-size: 14px;
	-webkit-transform: perspective(1px) translateZ(0);
	transform: perspective(1px) translateZ(0);
	overflow: hidden;
	position: relative;
	background: #fff;
	border:none;
	box-shadow:none;
	-webkit-transition: all 0.4S ease;
	-moz-transition: all 0.4S ease;
	transition: all 0.4S ease;
}
.button .btn:before{
	content: "";
	position: absolute;
	z-index: -1;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background: #fff;
	border-radius: 100%;
	-webkit-transform: scale(0);
	transform: scale(0);
	-webkit-transition: all 0.4S ease-out;
	-moz-transition: all 0.4S ease-out;
	transition: all 0.4S ease-out;
}
.button .btn:hover:before{
	-webkit-transform: scale(2);
	transform: scale(2);
}
.section{
	padding:90px 0;
}
.section-bg{
	/*background-image:url('../images/section-bg.png');*/
	background-repeat: no-repeat;
	background-position: center;
	background-position: top;
}
.section-title {
	text-align: center;
	margin-bottom: 40px;
	padding: 0 120px;
}
.section-title h2 {
	font-size: 38px;
	text-transform: capitalize;
	padding-bottom: 12px;
	position: relative;
	margin-bottom: 18px;
}
.section-title h2::before {
	position: absolute;
	left: 50%;
	content: "";
	width: 100px;
	height: 2px;
	background: #666;
	bottom: -1px;
	margin-left: -50px;
}
.section-title h2::after {
	position: absolute;
	left: 50%;
	content: "";
	width: 100px;
	height: 2px;
	background: #666;
	bottom: -6px;
	margin-left: -63px;
}
.section-title p {
	font-size: 15px;
}
/*Bread Crumbs */
.breadcrumbs {
	background: url('http://via.placeholder.com/2000x500');
	background-position: 100%;
	position: relative;
	background-repeat: no-repeat;
	background-size: cover;
	text-align: center;
	padding: 80px 0;
}
.breadcrumbs.overlay::before {
	opacity: 0.6;
	background: #252525;
}
.breadcrumbs h2 {
	color: #fff;
	text-transform: capitalize;
	font-size: 45px;
	font-weight: 700;
}
.breadcrumbs .bread-list {
	border-radius: 3px;
	background: transparent;
	display: inline-block;
	margin-top: 20px;
}
.breadcrumbs ul li {
	display: inline-block;
	position: relative;
}
.breadcrumbs ul li a {
	color: #fff;
	font-size: 18px;
	font-weight: 500;
	text-transform: capitalize;
}
.breadcrumbs ul li.active a {
	padding: 4px 20px;
	border-radius: 5px;
	color: #fff;
}
.breadcrumbs ul li i{
	margin:0px 15px;
}/*-------------------------
    36.Preloader css
---------------------------*/
.book_preload {
  position: fixed;
  width: 100%;
  height: 100%;
  background: #e09a17;
  z-index: 999999;
}
.book {
  top: 50%;
  left: 0;
  -webkit-transform: translateY(-50%);
  transform: translateY(-50%);
  position: relative;
  margin: 0 auto;
  border: 5px solid #ecf0f1;
  width: 100px;
  height: 60px;
}
.book__page {
  position: absolute;
  left: 50%;
  top: -5px;
  margin: 0 auto;
  border-top: 5px solid #ecf0f1;
  border-bottom: 5px solid #ecf0f1;
  border-right: 5px solid #ecf0f1;
  background: #da920c;
  width: 50px;
  height: 60px;
  -webkit-transform-origin: 0% 50%;
  transform-origin: 0% 50%;
  -webkit-animation: flip 1.2s infinite linear;
  animation: flip 1.2s infinite linear;
  -webkit-animation-fill-mode: forwards;
  animation-fill-mode: forwards;
}
.book__page:nth-child(1) {
  z-index: -1;
  -webkit-animation-delay: 1.4s;
  animation-delay: 1.4s;
}
.book__page:nth-child(2) {
  z-index: -2;
  -webkit-animation-delay: 2.8s;
  animation-delay: 2.8s;
}
.book__page:nth-child(3) {
  z-index: -3;
  -webkit-animation-delay: 4.2s;
  animation-delay: 4.2s;
}
@-webkit-keyframes flip {
  0% {
    -webkit-transform: perspective(600px) rotateY(0deg);
    transform: perspective(600px) rotateY(0deg);
  }
  20% {
    background: #da920c;
  }
  29.9% {
    background: #da920c;
  }
  30% {
    -webkit-transform: perspective(200px) rotateY(-90deg);
    transform: perspective(200px) rotateY(-90deg);
    background: #da920c;
  }
  54.999% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #da920c;
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #da920c;
  }
}
@keyframes flip {
  0% {
    -webkit-transform: perspective(600px) rotateY(0deg);
    transform: perspective(600px) rotateY(0deg);
  }
  20% {
    background: #da920c;
  }
  29.9% {
    background: #da920c;
  }
  30% {
    -webkit-transform: perspective(200px) rotateY(-90deg);
    transform: perspective(200px) rotateY(-90deg);
    background: #da920c;
  }
  54.999% {
    opacity: 1;
  }
  55% {
    opacity: 0;
  }
  60% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #da920c;
  }
  100% {
    -webkit-transform: perspective(200px) rotateY(-180deg);
    transform: perspective(200px) rotateY(-180deg);
    background: #da920c;
  }
}
/*--------------------------------------------------------------
# Elements
--------------------------------------------------------------*/
html {
	box-sizing: border-box;
}

*,
*:before,
*:after {
	/* Inherit box-sizing to make it easier to change the property for components that leverage other behavior; see https://css-tricks.com/inheriting-box-sizing-probably-slightly-better-best-practice/ */
	box-sizing: inherit;
}

body {
	background: #fff;
	/* Fallback for when there is no custom background color defined. */
}

hr {
	background-color: #ccc;
	border: 0;
	height: 1px;
	margin-bottom: 1.5em;
}

ul,ul li{
	list-style:none;
	padding:0;
	margin:0;
}

li > ul,
li > ol {
	margin-bottom: 0;
	margin-left: 1.5em;
}

dt {
	font-weight: bold;
}

dd {
	margin: 0 1.5em 1.5em;
}

img {
	height: auto;
	/* Make sure images are scaled correctly. */
	max-width: 100%;
	/* Adhere to container width. */
}

figure {
	margin: 1em 0;
	/* Extra wide images within figure tags don't overflow the content area. */
}

table {
	margin: 0 0 1.5em;
	width: 100%;
}

/*--------------------------------------------------------------
# Forms
--------------------------------------------------------------*/
button,
input[type="button"],
input[type="reset"],
input[type="submit"] {
	border: 1px solid;
	border-color: #ccc #ccc #bbb;
	border-radius: 3px;
	background: #e6e6e6;
	color: rgba(0, 0, 0, 0.8);
	line-height: 1;
}

input[type="text"],
input[type="email"],
input[type="url"],
input[type="password"],
input[type="search"],
input[type="number"],
input[type="tel"],
input[type="range"],
input[type="date"],
input[type="month"],
input[type="week"],
input[type="time"],
input[type="datetime"],
input[type="datetime-local"],
input[type="color"],
textarea {
	color: #666;
}

input[type="text"]:focus,
input[type="email"]:focus,
input[type="url"]:focus,
input[type="password"]:focus,
input[type="search"]:focus,
input[type="number"]:focus,
input[type="tel"]:focus,
input[type="range"]:focus,
input[type="date"]:focus,
input[type="month"]:focus,
input[type="week"]:focus,
input[type="time"]:focus,
input[type="datetime"]:focus,
input[type="datetime-local"]:focus,
input[type="color"]:focus,
textarea:focus {
	color: #111;
}

select {
	border: 1px solid #ccc;
}

textarea {
	width: 100%;
}

/*--------------------------------------------------------------
# Navigation
--------------------------------------------------------------*/
.main-navigation {
	clear: both;
	display: block;
	float: left;
	width: 100%;
}

.main-navigation ul {
	display: none;
	list-style: none;
	margin: 0;
	padding-left: 0;
}

.main-navigation ul ul {
	box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2);
	float: left;
	position: absolute;
	top: 100%;
	left: -999em;
	z-index: 99999;
}

.main-navigation ul ul ul {
	left: -999em;
	top: 0;
}

.main-navigation ul ul li:hover > ul,
.main-navigation ul ul li.focus > ul {
	left: 100%;
}

.main-navigation ul ul a {
	width: 200px;
}

.main-navigation ul li:hover > ul,
.main-navigation ul li.focus > ul {
	left: auto;
}

.main-navigation li {
	float: left;
	position: relative;
}

.main-navigation a {
	display: block;
	text-decoration: none;
}

/* Small menu. */
.menu-toggle,
.main-navigation.toggled ul {
	display: block;
}

@media screen and (min-width: 37.5em) {
	.menu-toggle {
		display: none;
	}
	.main-navigation ul {
		display: block;
	}
}

.site-main .comment-navigation, .site-main
.posts-navigation, .site-main
.post-navigation {
	margin: 0 0 1.5em;
	overflow: hidden;
}

.comment-navigation .nav-previous,
.posts-navigation .nav-previous,
.post-navigation .nav-previous {
	float: left;
	width: 50%;
}

.comment-navigation .nav-next,
.posts-navigation .nav-next,
.post-navigation .nav-next {
	float: right;
	text-align: right;
	width: 50%;
}

/*--------------------------------------------------------------
# Accessibility
--------------------------------------------------------------*/
/* Text meant only for screen readers. */
.screen-reader-text {
	border: 0;
	clip: rect(1px, 1px, 1px, 1px);
	clip-path: inset(50%);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute !important;
	width: 1px;
	word-wrap: normal !important; /* Many screen reader and browser combinations announce broken words as they would appear visually. */
}

.screen-reader-text:focus {
	background-color: #f1f1f1;
	border-radius: 3px;
	box-shadow: 0 0 2px 2px rgba(0, 0, 0, 0.6);
	clip: auto !important;
	clip-path: none;
	color: #21759b;
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: bold;
	height: auto;
	left: 5px;
	line-height: normal;
	padding: 15px 23px 14px;
	text-decoration: none;
	top: 5px;
	width: auto;
	z-index: 100000;
	/* Above WP toolbar. */
}

/* Do not show the outline on the skip link target. */
#content[tabindex="-1"]:focus {
	outline: 0;
}

/*--------------------------------------------------------------
# Alignments
--------------------------------------------------------------*/
.alignleft {
	display: inline;
	float: left;
	margin-right: 1.5em;
}

.alignright {
	display: inline;
	float: right;
	margin-left: 1.5em;
}

.aligncenter {
	clear: both;
	display: block;
	margin-left: auto;
	margin-right: auto;
}

/*--------------------------------------------------------------
# Clearings
--------------------------------------------------------------*/
.clear:before,
.clear:after,
.entry-content:before,
.entry-content:after,
.comment-content:before,
.comment-content:after,
.site-header:before,
.site-header:after,
.site-content:before,
.site-content:after,
.site-footer:before,
.site-footer:after {
	content: "";
	display: table;
	table-layout: fixed;
}

.clear:after,
.entry-content:after,
.comment-content:after,
.site-header:after,
.site-content:after,
.site-footer:after {
	clear: both;
}

/*--------------------------------------------------------------
# Widgets
--------------------------------------------------------------*/
.widget {
	margin: 0 0 10px;
	/* Make sure select elements fit in widgets. */
	margin: 0 0 10px;
    /* border: 1px solid #ccc; */
    border-radius: 0.25rem;
    overflow: hidden;
    box-shadow: 0 0 1px rgba(0,0,0,.2);
}

.widget select {
	max-width: 100%;
}

/*--------------------------------------------------------------
# Content
--------------------------------------------------------------*/
/*--------------------------------------------------------------
## Posts and pages
--------------------------------------------------------------*/
.sticky {
	display: block;
}

.hentry {
	margin: 0 0 1.5em;
}

.updated:not(.published) {
	display: none;
}

.page-content,
.entry-content,
.entry-summary {
	margin: 1.5em 0 0;
}

.page-links {
	clear: both;
	margin: 0 0 1.5em;
}

/*--------------------------------------------------------------
## Comments
--------------------------------------------------------------*/
.comment-content a {
	word-wrap: break-word;
}

.bypostauthor {
	display: block;
}

/*--------------------------------------------------------------
# Infinite scroll
--------------------------------------------------------------*/
/* Globally hidden elements when Infinite Scroll is supported and in use. */
.infinite-scroll .posts-navigation,
.infinite-scroll.neverending .site-footer {
	/* Theme Footer (when set to scrolling) */
	display: none;
}

/* When Infinite Scroll has reached its end we need to re-display elements that were hidden (via .neverending) before. */
.infinity-end.neverending .site-footer {
	display: block;
}

/*--------------------------------------------------------------
# Media
--------------------------------------------------------------*/
.page-content .wp-smiley,
.entry-content .wp-smiley,
.comment-content .wp-smiley {
	border: none;
	margin-bottom: 0;
	margin-top: 0;
	padding: 0;
}

/* Make sure embeds and iframes fit their containers. */
embed,
iframe,
object {
	max-width: 100%;
}

/* Make sure logo link wraps around logo image. */
.custom-logo-link {
	display: inline-block;
}

/*--------------------------------------------------------------
## Captions
--------------------------------------------------------------*/
.wp-caption {
	margin-bottom: 1.5em;
	max-width: 100%;
}

.wp-caption img[class*="wp-image-"] {
	display: block;
	margin-left: auto;
	margin-right: auto;
}

.wp-caption .wp-caption-text {
	margin: 0.8075em 0;
}

.wp-caption-text {
	text-align: center;
}

/*--------------------------------------------------------------
## Galleries
--------------------------------------------------------------*/
.gallery {
	margin-bottom: 1.5em;
}

.gallery-item {
	display: inline-block;
	text-align: center;
	vertical-align: top;
	width: 100%;
}

.gallery-columns-2 .gallery-item {
	max-width: 50%;
}

.gallery-columns-3 .gallery-item {
	max-width: 33.33%;
}

.gallery-columns-4 .gallery-item {
	max-width: 25%;
}

.gallery-columns-5 .gallery-item {
	max-width: 20%;
}

.gallery-columns-6 .gallery-item {
	max-width: 16.66%;
}

.gallery-columns-7 .gallery-item {
	max-width: 14.28%;
}

.gallery-columns-8 .gallery-item {
	max-width: 12.5%;
}

.gallery-columns-9 .gallery-item {
	max-width: 11.11%;
}

.gallery-caption {
	display: block;
}